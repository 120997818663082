import * as React from 'react'
import { Global, css } from '@emotion/react'
import BurgerMenu from './burgerMenu'
import Header from './header'
import Footer from './footer'

type LayoutProps = {
  children: React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const sanitize = require('sanitize.css')

const styles = css`
  html {
    font-size: 62.5%;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  ${sanitize}

  main {
    margin-top: 80px;
    margin-bottom: 80px;
    padding-left: 40px;
    padding-right: 40px;
    @media (max-width: 767px) {
      margin-top: 30px;
      margin-bottom: 40px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`

const Layout = ({ children }: LayoutProps) => (
  <>
    <Global styles={styles} />
    <BurgerMenu />
    <Header />
    <main>{children}</main>
    <Footer />
  </>
)

export default Layout
