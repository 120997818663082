import React from 'react'
import { Link } from 'gatsby'
import { slide as Menu } from 'react-burger-menu'
import burgerMenuStyle from '../styles/components/burgerMenuStyle'

const BurgerMenu = () => {
  const [isOpen, setOpen] = React.useState(false)

  const handleIsOpen = () => {
    setOpen(!isOpen)
  }

  const closeSideBar = () => {
    setOpen(false)
  }

  return (
    <div css={burgerMenuStyle}>
      <Menu right isOpen={isOpen} onOpen={handleIsOpen} onClose={handleIsOpen}>
        <Link className="link" to="/" onClick={closeSideBar}>
          Home
        </Link>
        <Link className="link" to="/#portfolio" onClick={closeSideBar}>
          Portfolio
        </Link>
        <ul className="sns">
          <li>
            <a
              href="https://twitter.com/meimei_fish"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="icon"
                src="/images/icons/twitter.svg"
                alt="twitter"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/meimei_fish_model"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="icon"
                src="/images/icons/instagram.svg"
                alt="instagram"
              />
            </a>
          </li>
        </ul>
      </Menu>
    </div>
  )
}

export default BurgerMenu
