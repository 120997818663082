import React from 'react'
import headerStyle from '../styles/components/headerStyle'
import HeaderContent from './headerContent'

const Header = () => (
  <header css={headerStyle}>
    <HeaderContent />
  </header>
)

export default Header
