import { css } from '@emotion/react'

const headerStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  font-family: 'Montserrat', sans-serif;
  @media (max-width: 767px) {
    height: 50px;
  }
`

export default headerStyle
